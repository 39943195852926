// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

body {
  background-position: center top;
  background-size: 100% auto;
  background-image: url("/media/demos/demo4/header.jpg") !important;
}

@media screen and (max-width: 1200px) {
  body {
    background-size: 100% 300px;
  }
}
