/**
Default
**/
.react-pagination-js-default ul{
    list-style: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
    font-size: .875em;
    font-family: inherit;
  }
  
  li.page.disabled {
    cursor: default;
    pointer-events: none;
    color: #999999;
  }
  
  .react-pagination-js-default .page {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
    border-radius: 3px;
    border: solid 1px #c0c0c0;
    background: #e9e9e9;
    box-shadow: inset 0px 1px 0px rgba(255,255,255, .8), 0px 1px 3px rgba(0,0,0, .1);
  
    font-weight: bold;
    text-decoration: none;
    color: #717171;
    text-shadow: 0px 1px 0px rgba(255,255,255, 1);
    cursor: pointer;
  }
  .react-pagination-js-default .is-active {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
    border-radius: 3px;
    border: solid 1px #c0c0c0;
    background: #616161;
    box-shadow: inset 0px 0px 8px rgba(0,0,0, .5), 0px 1px 0px rgba(255,255,255, .8);
  
    font-weight: bold;
    text-decoration: none;
    color: #f0f0f0;
    text-shadow: 0px 0px 3px rgba(0,0,0, .5);
    cursor: pointer;
  }
  .react-pagination-js-default .pageElli {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
  
    font-weight: bold;
    text-decoration: none;
    color: #717171;
  }
  
  
  .react-pagination-js-default .page:hover, .page.gradient:hover {
    background: #fefefe;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#FEFEFE), to(#f0f0f0));
    background: -moz-linear-gradient(0% 0% 270deg,#FEFEFE, #f0f0f0);
  }
  
  .react-pagination-js-default .page.active {
    border: none;
    background: #616161;
    box-shadow: inset 0px 0px 8px rgba(0,0,0, .5), 0px 1px 0px rgba(255,255,255, .8);
    color: #f0f0f0;
    text-shadow: 0px 0px 3px rgba(0,0,0, .5);
  }
  
  /**
  Dark
  **/
  .react-pagination-js-dark ul{
    list-style: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
    font-size: .875em;
    font-family: inherit;
  }
  .react-pagination-js-dark .page {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
    border-radius: 3px;
  
    border: solid 1px #c0c0c0;
    background: #616161;
    box-shadow: inset 0px 0px 8px rgba(0,0,0, .5), 0px 1px 0px rgba(255,255,255, .8);
    color: #f0f0f0;
  
    text-decoration: none;
    cursor: pointer;
  }
  .react-pagination-js-dark .is-active {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
    border-radius: 3px;
    
    border: solid 1px #c0c0c0;
    background: #332e2e;
    text-shadow: 0px 1px 0px rgba(255,255,255, 1);
    color: #717171;
   
  
    font-weight: bold;
    text-decoration: none;
    text-shadow: 0px 0px 3px rgba(0,0,0, .5);
    cursor: pointer;
  }
  .react-pagination-js-dark .pageElli {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
  
    font-weight: bold;
    text-decoration: none;
    color: #717171;
  }
  
  
  .react-pagination-js-dark .page:hover, .page.gradient:hover {
    background: #fefefe;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(0,0,0, .5)), to(rgba(0,0,0, .5)));
    background: -moz-linear-gradient(0% 0% 270deg,rgba(0,0,0, .5), rgba(0,0,0, .5));
  }
  
  .react-pagination-js-dark .page.active {
    border: none;
    background: #616161;
    box-shadow: inset 0px 0px 8px rgba(0,0,0, .5), 0px 1px 0px rgba(255,255,255, .8);
    color: #f0f0f0;
    text-shadow: 0px 0px 3px rgba(0,0,0, .5);
  }
  
  
  /**
  Bootstrap css
  **/
  .react-pagination-js-bootstrap ul{
  
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
    display: inline-block;
    *display: inline;
    font-size: .875em;
    /* IE7 inline-block hack */
  
    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    font-family: inherit;
  }
  .react-pagination-js-bootstrap .page {
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 0;
    cursor: pointer;
    color: #0088cc;
  }
  .react-pagination-js-bootstrap .is-active {
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 0;
    color: #999999;
    background-color: #f5f5f5;
    cursor: default;
  }
  .react-pagination-js-bootstrap .pageElli {
    float: left;
    padding: 0 14px;
    line-height: 34px;
    text-decoration: none;
    border: 1px solid #ddd;
    border-left-width: 0;
    color: #999999;
    cursor: default;
  
  }
  
  
  .react-pagination-js-bootstrap .page:hover {
    background-color: #f5f5f5;
  }
  
  .react-pagination-js-bootstrap .page.active {
    color: #999999;
    cursor: default;
  }
  
  
  .react-pagination-js-bootstrap .page:first-child  {
    border-left-width: 1px;
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
    
  }
  .react-pagination-js-bootstrap  .page:last-child  {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
  }
  
  /**
  Border Bottom
  */
  .react-pagination-js-border-bottom ul{
    list-style: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
    font-size: .875em;
    font-family: inherit;
    line-height: 2em;
  }
  .react-pagination-js-border-bottom .page {
    display: inline-block;
    padding: 0px 1px;
      margin: 0px 9px;
    text-decoration: none;
    cursor: pointer;
  }
  .react-pagination-js-border-bottom .is-active {
    display: inline-block;
    padding: 0px 1px;
    margin: 0px 9px;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
    border-bottom: 1px solid #0088cc;
    color: #0088cc
  }
  .react-pagination-js-border-bottom .pageElli {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
  
    font-weight: bold;
    text-decoration: none;
    color: #717171;
    
  }
  
  
  .react-pagination-js-border-bottom .page:hover{
    font-weight: bold;
    border-bottom: 1px solid #0088cc;
  }
  /**
  Circle
  **/
  
  .react-pagination-js-circle ul{
    list-style: none;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
    font-size: .875em;
    font-family: inherit;
    line-height: 2em;
  }
  .react-pagination-js-circle .page {
    display: inline-block;
    padding: 0px 1px;
      margin: 0px 9px;
    text-decoration: none;
    cursor: pointer;
    
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;
    margin: auto 5px;
    text-align: center;
    position: relative;
  }
  .react-pagination-js-circle .is-active {
    display: inline-block;
    padding: 0px 1px;
    margin: 0px 9px;
    text-decoration: none;
    cursor: pointer;
    font-weight: bold;
  
    color: #0088cc;
    border: 3px solid #0088cc;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    padding: 0;
    margin: auto 5px;
    text-align: center;
    position: relative;
  }
  .react-pagination-js-circle .pageElli {
    display: inline-block;
    padding: 0px 9px;
    margin-right: 4px;
  
    font-weight: bold;
    text-decoration: none;
    color: #717171;
    
  }
  
  
  .react-pagination-js-circle .page:hover{
    font-weight: bold;
    color: #0088cc;
    border: 3px solid #0088cc;
  }
  .circle a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    text-align: center;
    margin: auto 5px;
  }
  
  .circle a.is-active {
    border: 3px solid #2ecc71;
    border-radius: 100%;
  
  }